import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useSocket from './useSocket';

const LogViewer = () => {
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.on('log_message', (data) => {
                toast.info(data.message);
            });

            return () => {
                socket.off('log_message'); // Clean up event listener on component unmount
            };
        }
    }, [socket]);

    return null;
};

export default LogViewer;
