import React, { useState } from 'react';
import axios from './axiosConfig';
import './SearchComponent.css';

const SearchComponent = () => {
  const [hu, setHu] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    description_2: '',
    warranty: '',
    scratches: false,
    dirt: false,
    photosOfScratchesDirt: false,
    veryGoodCondition: false,
  });

  console.log(formData)

  const handleSearch = async () => {
    setError(null);
    setResult(null);

    try {
      const response = await axios.get(`/api/search/${hu}`);
      setResult(response.data);
      setFormData((prevData) => ({
            ...prevData,
            ...response.data.prepared_data,
        }));
    } catch (err) {
      setError('Wystąpił błąd podczas wyszukiwania.');
    }
  };

  const handleSend = async () => {
    setError(null);
    setResult(null);

    try {
      const response = await axios.put(`/api/search/${result.hu}`, formData);
      handleSearch();
    } catch (err) {
      setError('Wystąpił błąd podczas wyszukiwania.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
      <div className="search-component">
        <div className="container">
          <input
              type="text"
              className="input"
              placeholder="Wpisz zapytanie..."
              value={hu}
              onChange={(e) => setHu(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button onClick={handleSearch}>Szukaj</button>
          {error && <div>{error}</div>}
          {result && result.info && (
              <div className="result">
                <div>{result.info}</div>
              </div>
          )}
          {result && !result.info && (
              <>
                <div className="result">
                  <div>{result.info}</div>
                  <div>{result.name}</div>
                  <div>hu: {result.hu}</div>
                  <div>data zakupu: {result.bought_date}</div>
                  <div>opis: {result.description}</div>
                </div>
                <input
                    type="text"
                    className="input"
                    name="warranty"
                    placeholder="gwarancja"
                    value={formData.warranty}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    className="input"
                    name="description_2"
                    placeholder="opis 2"
                    value={formData.description_2}
                    onChange={handleInputChange}
                />
                <div>
                  {[
                    {label: 'Zarysowania', name: 'scratches'},
                    {label: 'Zabruszenia', name: 'dirt'},
                    {label: 'Zdjęcia zabrudzeń lub zarysowań', name: 'photosOfScratchesDirt'},
                    {label: 'Stan jest bardzo dobry', name: 'veryGoodCondition'},
                  ].map(({label, name}) => (
                      <label className="checkbox-label" key={name}>
                        <input
                            type="checkbox"
                            name={name}
                            checked={formData[name]}
                            onChange={handleInputChange}
                        />
                        {label}
                      </label>
                  ))}
                </div>
                <button onClick={handleSend}>Wyslij</button>
              </>
          )}
        </div>
      </div>
  );
};

export default SearchComponent;
