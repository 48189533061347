import React, { useState, useEffect } from 'react';
import axios from './axiosConfig';
import './Conversations.css'
import {Link, useParams} from "react-router-dom";

const Conversations = () => {
  const {olx_id} = useParams();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`/api/conversations/offer/${olx_id}`);
      const data = await response.data;
      console.log(data)
      if (data.length > 0) {
        setItems(prevItems => [...prevItems, ...data]);
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  useEffect(() => {
    fetchItems();

    const intervalId = setInterval(() => {
      fetchItems();
    }, 300000); // 300000 ms = 5 minutes

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className="item-list">
        {items.map((item, index) => (
          <Link to={`/conversations/${item.conversation.id}`} key={index} className="item-card">
            <p>{item.conversation.respondent.name}</p>
            <p>{item.conversation.ad.title}</p>
            {item.messages.length > 0 && (
              <p>{item.messages[0].text}</p>
            )}
          </Link>
        ))}
      </div>
      {hasMore && (
        <button onClick={fetchItems} className="load-more-button">
          Load More
        </button>
      )}
    </div>
  );
};

export default Conversations;
