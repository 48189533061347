import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL
  : '';

const useSocket = () => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const newSocket = io(`${apiUrl}/ws`, {
                transports: ['websocket'],
                query: { token },
            });

            setSocket(newSocket);

            return () => {
                newSocket.disconnect();
            };
        }
    }, []);

    return socket;
};

export default useSocket;
