import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import Offers from "./Offers";
import OfferDetails from "./OfferDetails";
import PhotoSelector from "./PhotoSelector";
import Conversations from "./Conversations";
import useLoader from "./useLoader";
import Loader from "./Loader";
import SearchComponent from "./SearchComponent";
import LogViewer from "./LogViewer";


function App() {
  const loading = useLoader();

    return (
      <div>
        <div style={{ padding: '50px' }}>
          {loading && <Loader />}
          <ToastContainer />
          <LogViewer />
          <Routes>
            <Route path="/offers" element={<Offers />} />
            <Route path="/offers/details/:hu" element={<OfferDetails />} />
            <Route path="/offers/photos/:hu" element={<PhotoSelector />} />
            <Route path="/conversations/offer/:olx_id" element={<Conversations />} />
            <Route path="/search" element={<SearchComponent />} />
          </Routes>
        </div>
      </div>
    );
}

export default App;
