import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL
  : '';

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

const fetchToken = async () => {
  const storedToken = localStorage.getItem('token');
  if (storedToken && storedToken !== 'undefined') {
    return storedToken;
  }

  const uniqueId = uuidv4();
  try {
    const response = await axios.put(`${apiUrl}/api/user/get_token`, {
      userId: uniqueId,
    });
    const newToken = response.data;
    localStorage.setItem('token', newToken);
    return newToken;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

axiosInstance.interceptors.request.use(
  async config => {
    let token = localStorage.getItem('token');
    if (!token || token === 'undefined' || token === null || token === 'null') {
      token = await fetchToken(); // Fetch token if not in localStorage
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
