import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from './axiosConfig';
import {Form, Button, Table} from 'react-bootstrap';

const OfferDetails = () => {
    const {hu} = useParams();
    const navigate = useNavigate()
    const [offerData, setOfferData] = useState({
        offer_title: '',
        start_price: '',
        price_discount: '',
        reason_for_discount: '',
        product_name_for_description: '',
    });
    const [additionalData, setAdditionalData] = useState({});
    const [dynamicFields, setDynamicFields] = useState({});
    const [selectedDynamicFields, setSelectedDynamicFields] = useState({});
    const [keyFeatures, setKeyFeatures] = useState([]);
    const [error, setError] = useState('');
    const [submissionError, setSubmissionError] = useState('');
    const [categories, setCategories] = useState([]);

    const fetchOfferDetails = async () => {
        try {
            const response = await axios.get(`/api/offers/${hu}`);
            const data = response.data;

            setOfferData({
                offer_title: data.offer_title || '',
                start_price: data.start_price || '',
                price_discount: data.price_discount || '',
                reason_for_discount: data.reason_for_discount || '',
                product_name_for_description: data.product_name_for_description || '',
            });

            setAdditionalData(data);

            const parameters = data.parameters || {};
            const filteredParameters = Object.keys(parameters)
                .filter(key => key !== 'price' && key !== 'state')
                .reduce((obj, key) => {
                    obj[key] = parameters[key];
                    return obj;
                }, {});
            setDynamicFields(filteredParameters);

            const initialSelectedDynamicFields = {};
            for (const key in filteredParameters) {
                initialSelectedDynamicFields[filteredParameters[key].code] = filteredParameters[key].chosen_value || '';
            }
            setSelectedDynamicFields(initialSelectedDynamicFields);

            if (data.key_features) {
                setKeyFeatures(data.key_features);
            }
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    useEffect(() => {
        fetchOfferDetails();
    }, [hu]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name === "product_name_for_description" && value.length > 70) {
            setError("Maksymalna długość to 70 znaków.");
        } else {
            setError('');
        }

        setOfferData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDynamicFieldChange = (code, key) => {
        setSelectedDynamicFields(prevState => ({
            ...prevState,
            [code]: key
        }));
    };

    const handleAddKeyFeature = () => {
        setKeyFeatures(prevFeatures => [...prevFeatures, {key: '', value: ''}]);
    };

    const handleKeyFeatureChange = (index, field, value) => {
        const updatedFeatures = [...keyFeatures];
        updatedFeatures[index][field] = value;
        setKeyFeatures(updatedFeatures);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (error) return;

        if (Object.keys(selectedDynamicFields).length !== Object.keys(dynamicFields).length) {
            setSubmissionError('Wszystkie pola dynamiczne muszą być wybrane.');
            return;
        }

        setSubmissionError('');

        try {
            const response = await axios.put(`/api/offers/${hu}`, {
                ...offerData,
                selectedDynamicFields: selectedDynamicFields,
                key_features: keyFeatures,
            });
        } catch (error) {
            console.error('Error updating offer:', error);
        }
    };

    const handleCategoriesOLX = async () => {
        try {
            const response = await axios.get(`/api/offers/${hu}/get_categories`);
            if (response.status === 200) {
                setCategories(response.data);
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    const handlePrepareOLX = async () => {
        try {
            const response = await axios.put(`/api/offers/${hu}/prepare_data_for_olx`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    const handleMakeReservation = async () => {
        try {
            const response = await axios.put(`/api/offers/reservation/${additionalData.olx_id}`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

     const handleRemoveReservation = async () => {
        try {
            const response = await axios.delete(`/api/offers/reservation/${additionalData.olx_id}`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

     const handleMakeDiscount = async () => {
        try {
            const response = await axios.put(`/api/offers/discount/${additionalData.olx_id}`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

     const handleRemoveDiscount = async () => {
        try {
            const response = await axios.delete(`/api/offers/discount/${additionalData.olx_id}`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

     const handleSendCategory = async (id, name) => {
        try {
            const response = await axios.put(`/api/offers/${hu}/categories`, {
                id: id,
                name: name
            });
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error handleSendCategory for OLX:', error);
        }
    };

    const handlePublishOLX = async () => {
        try {
            const response = await axios.put(`/api/offers/${hu}/add_olx_offer_to_www`);
            if (response.status === 200) {
                fetchOfferDetails();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    const handleRemoveKeyFeature = (index) => {
        setKeyFeatures(prevFeatures => prevFeatures.filter((_, i) => i !== index));
    };

    const handleViewPhotos = () => {
        navigate(`/offers/photos/${hu}`); // Redirect to the photos URL
    };

    const handleViewConversations = () => {
        navigate(`/conversations/offer/${additionalData.olx_id}`);
    };



    return (
        <div>
            <h1>Detale oferty</h1>

            <div className="additional-info">
                <h3>Dodatkowe informacje</h3>
                <p><strong>OLX id:</strong> {additionalData.olx_id}</p>
                <p><strong>Nazwa produktu:</strong> {additionalData.product_name}</p>
                <p><strong>PLU:</strong> {additionalData.plu}</p>
                <p><strong>Cena zakupu:</strong> {additionalData.bought_price}</p>
                <p>
                    <strong>Opis: </strong>{additionalData.description} | {additionalData.prepared_data && additionalData.prepared_data.description_2}
                </p>
                <p><strong>Liczba zdjęć:</strong> {additionalData.images_amount}</p>
                <p><strong>Rezerwacja: </strong>{additionalData.olx_is_reservation ? 'TAK' : 'NIE'} </p>
                <p><strong>Obniżka: </strong>{additionalData.olx_is_discount ? 'TAK' : 'NIE'} </p>
                <p><strong>Kategoria: </strong>{additionalData.olx_category && additionalData.olx_category.name} </p>
                <Button variant="primary" onClick={handleCategoriesOLX}>
                    Pobierz kategorie OLX
                </Button>
                <Button variant="primary" onClick={handlePrepareOLX}>
                    Przygotuj OLX
                </Button>
                <Button variant="primary" onClick={handlePublishOLX}>
                    Wystaw OLX
                </Button>
                <Button variant="info" onClick={handleViewPhotos}>
                    Zobacz zdjęcia
                </Button>
                <Button variant="info" onClick={handleViewConversations}>
                    Wiadomosci
                </Button>
                <Button variant="info" onClick={handleMakeReservation}>
                    Rezerwuj
                </Button>
                <Button variant="info" onClick={handleRemoveReservation}>
                    Usuń rezerwacje
                </Button>
                <Button variant="info" onClick={handleMakeDiscount}>
                    Dodaj obniżkę
                </Button>
                <Button variant="info" onClick={handleRemoveDiscount}>
                    Usuń obniżkę
                </Button>

            </div>

            <div>
                {categories.map((category) => (
                    <div key={category.id} style={{marginBottom: '10px'}}>
                        <div>
                            <strong>ID:</strong> {category.id}, <strong>Name:</strong> {category.name}
                        </div>
                        <button onClick={() => handleSendCategory(category.id, category.name)}>
                            Wyślij
                        </button>
                    </div>
                ))}
            </div>

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="offer_title">
                    <Form.Label>Offer Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="offer_title"
                        value={offerData.offer_title}
                        onChange={handleChange}
                        maxLength={70}
                    />
                    <Form.Text className="text-muted">
                        {offerData.offer_title.length}/70
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="price_discount">
                    <Form.Label>Start Price</Form.Label>
                    <Form.Control
                        type="text"
                        name="start_price"
                        value={offerData.start_price}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="price_discount">
                    <Form.Label>Price Discount</Form.Label>
                    <Form.Control
                        type="text"
                        name="price_discount"
                        value={offerData.price_discount}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="reason_for_discount">
                    <Form.Label>Reason for Discount</Form.Label>
                    <Form.Control
                        type="text"
                        name="reason_for_discount"
                        value={offerData.reason_for_discount}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="product_name_for_description">
                    <Form.Label>Product Name for Description</Form.Label>
                    <Form.Control
                        type="text"
                        name="product_name_for_description"
                        value={offerData.product_name_for_description}
                        onChange={handleChange}
                    />
                </Form.Group>

                {Object.entries(dynamicFields).map(([key, param]) => (
                    <Form.Group key={param.id} controlId={param.code}>
                        <Form.Label>{param.label}</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedDynamicFields[param.code] || ''}
                            onChange={(e) => handleDynamicFieldChange(param.code, e.target.value)}
                        >
                            <option value="">Wybierz...</option>
                            {param.values && param.values.map(value => (
                                <option key={value.key} value={value.key} disabled={value.disabled}>
                                    {value.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                ))}

                <h3>Key Features</h3>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keyFeatures.map((feature, index) => (
                        <tr key={index}>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={feature.key}
                                    onChange={(e) => handleKeyFeatureChange(index, 'key', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={feature.value}
                                    onChange={(e) => handleKeyFeatureChange(index, 'value', e.target.value)}
                                />
                            </td>
                            <td>
                                <Button variant="danger" onClick={() => handleRemoveKeyFeature(index)}>
                                    Remove
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Button variant="success" onClick={handleAddKeyFeature}>
                    Add Key Feature
                </Button>

                {submissionError && <div className="text-danger">{submissionError}</div>}

                <Button variant="primary" type="submit" disabled={error}>
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default OfferDetails;
