import React, {useState, useEffect} from 'react';
import axios from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import {Table, Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';

import './Offers.css';

const Offers = () => {
    const [offers, setOffers] = useState([]);

    const [selectedOffer, setSelectedOffer] = useState(null);

    const navigate = useNavigate();

    const fetchOffers = async () => {
        try {
           const response = await axios.get(`/api/offers/`);
           const json = response.data;
           setOffers(json);
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    const handleOlxIdDoubleClick = (offer) => {
        navigate('/offers/details/' + offer.hu);
    };

    const handleAddNewProducts = async () => {
        try {
            const response = await axios.put(`/api/invoice/fetch_all_new_orders`);
            if (response.status === 200) {
                fetchOffers();
            }
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    const handleHowMuchToPay = async () => {
        try {
            const response = await axios.put(`/api/invoice/how_much_to_pay`);
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    const handleMakeDiscount = async () => {
        try {
            const response = await axios.put(`/api/invoice/invoke_make_discount`);
        } catch (error) {
            console.error('Error preparing data for OLX:', error);
        }
    };

    return (
        <Container fluid>
            <div>
                <Button variant="primary" onClick={handleAddNewProducts}>
                    Dodaj produkty
                </Button>
                <Button variant="primary" onClick={handleHowMuchToPay}>
                    ile do zapłaty
                </Button>
                <Button variant="primary" onClick={handleMakeDiscount}>
                    Obniż ceny
                </Button>
            </div>
            <h1 className="text-center my-4">Oferty</h1>
            <Table striped bordered hover responsive style={{ width: '100%' }}>
                <thead>
                <tr>
                    <th>lp1</th>
                    <th>HU</th>
                    <th className="wide-column">Nazwa</th>
                    <th>Data zakupu</th>
                    <th>plu</th>
                    <th>Ceny</th>
                    <th>nr zamowieni</th>
                    <th className="wide-column">opis</th>
                    <th>olx id</th>
                    <th>Wiadomość</th>
                </tr>
                </thead>
                <tbody>
                    {offers.map((offer, index) => (
                        <tr key={offer.hu}
                            className={offer.description_2 && offer.exist_photos && !offer.olx_id ? 'bg-lightpink' : ''}>
                            <td>{index + 1}</td>
                            <td>{offer.hu}</td>
                            <td className="wide-column">{offer.product_name}</td>
                            <td>{offer.bought_date && offer.bought_date.$date}</td>
                            <td>{offer.plu}</td>
                            <td>{offer.bought_price}/{offer.olx_actual_price}/{offer.expected_profit}</td>
                            <td>{offer.order_number}</td>
                            <td className="wide-column">{offer.description} {offer.description_2}</td>
                            <td onDoubleClick={() => handleOlxIdDoubleClick(offer)}>
                                {offer.olx_id}
                                {offer.olx_is_discount && ' D '}
                                {offer.olx_is_reservation && ' R '}
                            </td>
                            <td>
                                <FontAwesomeIcon icon={faEnvelope} className="text-primary"/> {/* Envelope icon */}
                                <span className="mx-2">{offer.messages && offer.messages.conversation_count}/{offer.messages && offer.messages.message_count}</span> {/* Number next to the icon */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Offers;
