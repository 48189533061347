import axios from './axiosConfig';
import { useState, useEffect } from 'react';

let activeRequests = 0;
const useLoader = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Interceptor wysyłania żądania
    const requestInterceptor = axios.interceptors.request.use((config) => {
      activeRequests++;
      setLoading(true);  // Pokazuje loader
      return config;
    });

    // Interceptor odpowiedzi lub błędu
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        activeRequests--;
        if (activeRequests === 0) setLoading(false);  // Ukrywa loader
        return response;
      },
      (error) => {
        activeRequests--;
        if (activeRequests === 0) setLoading(false);  // Ukrywa loader nawet w przypadku błędu
        return Promise.reject(error);
      }
    );

    // Czyszczenie interceptorów po demontażu komponentu
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return loading;
};

export default useLoader;
