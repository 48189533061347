import React, {useEffect, useState} from 'react';
import axios from './axiosConfig';
import {useParams} from "react-router-dom";

function PhotoSelector() {
    const {hu} = useParams();
    const [photos, setPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [rotationStates, setRotationStates] = useState({}); // Przechowywanie stanu obrotu dla każdego zdjęcia

    const fetchPhotos = async () => {
        try {
            const response = await axios.get(`/api/offers/photos/${hu}`);
            setPhotos(response.data.photos);
            setSelectedPhotos([])
            setRotationStates({})
        } catch (error) {
            console.error("Error fetching photos:", error);
        }
    };

    useEffect(() => {
        fetchPhotos();
    }, [hu]);

    // Obsługa zaznaczania zdjęć
    const handleSelectPhoto = (photo) => {
        setSelectedPhotos((prev) =>
            prev.includes(photo) ? prev.filter((p) => p !== photo) : [...prev, photo]
        );
    };

    // Obsługa zmiany stanu checkboxa dla obrotu
    const handleRotateChange = (photo) => {
        setRotationStates((prev) => ({
            ...prev,
            [photo]: !prev[photo], // Zmieniaj stan obrotu dla danego zdjęcia
        }));
    };

    // Wysyłanie danych do API
    const handleCombinePhotos = async () => {
        const photoData = selectedPhotos.map((photo) => ({
            photoName: photo,
            isRotated: rotationStates[photo] || false, // Domyślnie false, jeśli checkbox nie był zaznaczony
        }));

        try {
            const response = await axios.post(`/offers/photos/${hu}`, {
                photos: photoData,
            });
            if (response.status === 200) { // Sprawdź, czy odpowiedź jest pozytywna
                console.log("Success:", response.data);
                // Połączono zdjęcia, teraz pobierz je ponownie
                await fetchPhotos(); // Wywołaj ponownie pobranie zdjęć
            }
        } catch (error) {
            console.error("Error combining photos:", error);
        }
    };

    return (
        <div>
            <h2>Wybierz zdjęcia do połączenia i określ, czy mają być obrócone:</h2>
            <div>{photos.length} zdjęć</div>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {photos.map((photo, index) => (
                    <div key={index} style={{margin: '10px', textAlign: 'center'}}>
                        <img
                            src={photo.photoUrl}
                            alt={`photo-${index}`}
                            style={{
                                width: '150px', // Skalowanie w zależności od orientacji
                                height: 'auto',  // Zachowanie proporcji zdjęcia
                                border: selectedPhotos.includes(photo.photoName) ? '2px solid blue' : 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleSelectPhoto(photo.photoName)}
                        />
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rotationStates[photo.photoName] || false} // Sprawdź, czy zdjęcie jest zaznaczone jako obrócone
                                    onChange={() => handleRotateChange(photo.photoName)}
                                />
                                Obróć zdjęcie
                            </label>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleCombinePhotos}>
                Połącz zdjęcia
            </button>
        </div>
    );
}

export default PhotoSelector;